.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

}

.color-nav {
  background-color: #5DBDB9;
}

/*Language Button*/

.btn {
  font-size: 1rem !important;
  padding: 0.2rem 0.8rem !important;
  margin: 0.2rem !important;
  border-radius: 5rem !important;
  border: 1px solid #ccc;
}

.btn:hover {
  box-shadow: 0 0 40px rgba(255, 114, 27, 0.576);
}

.btn-primary {
  background-color: #0170BB;
  border-color: #0170BB;
}

.btn-primary:hover {
  background-color: #F4460F !important;
  border-color: #F4460F !important;
}

/*Header css start here*/


.d-block {
  display: block;
  width: 100%;
  height: 40rem;
}

.description_img1 {
  font-size: 30px;
  text-shadow: rgb(3, 3, 3) 0.1em 0.1em 0.2em
}

.App-header {
  background-color: #f8fafd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #61dafb;
}

.header_img {
  object-fit: fill;
  opacity: 0.7;
}

.search-button {
  border-radius: 500px;
  padding: 5px;
  background: transparent;
  border: none;
}

.card_event {
  box-shadow: 0 1px 10px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 200ms ease-in;
  width: 40%;
  margin: 5% auto 5% auto;
  padding: 10px;
}

.hr {
  border: #61dafb solid 2px;
  border-radius: 5rem;
  background-color: #61dafb;
}

.card_body>img {
  height: 12rem;
  width: 100%;
  object-fit: contain;
}

.card_title {
  padding: 1rem;
  text-align: center;
}

.city_date_time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.city_date_time>p {
  margin-bottom: 0;
}

/* GROW ELLIPSE BUTTON STYLES */
.ov-btn-grow-ellipse {
  background-color: #61dafb;
  color: #ffffff;
  border: 2px solid;
  padding: 1rem;
  margin: 1rem;
  border-radius: 500px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.color-nav {
  background-color: #5DBDB9;
  font-family: 'Lato';
  color: #ffffff;
}

.logo {
  margin-left: 30px;
  margin-right: 80px;
}

/*Footer css start here*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system;
}

.container-footer {
  max-width: 100%;
  background-color: #5DBDB9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 6%;
}

/*Evaluate if still necessary */
.row {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 20px 0;
  flex-direction: row;
}

.row-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  padding: 20px 0;
  flex-wrap: nowrap;
  margin: 38px 0 38px 0;
}

.integrate-logo {
  margin-top: 10%;
}

ul {
  list-style: none;
}

.footer-col {
  width: 53%;
  padding: 0 15px;
  margin-left: 70px;
  margin-right: 70px;
}

.footer-col h4 {
  font-size: 18px;
  color: "Black";
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #ffffff;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 16px;
  color: #fdfdfd;
  text-decoration: none;
  font-weight: 300;
  color: #0a0606;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #ffffff;
}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #A2DBEF;
}

/*responsive*/
@media(max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media(max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}

@media(max-width: 896px) {

  .row-footer {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .footer-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    text-align: center;
    margin: 0 0 20px 0;
  }

  .footer-col>ul {
    padding: unset;
  }

  .logos-footer {
    text-align: center;
  }

  .integrate-logo {
    margin-top: 8%;
    margin-bottom: 8%;
  }

}

/*Map CSS*/
.leaflet-container {
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 2rem;
  width: 60vw;
  height: 60vh;
}