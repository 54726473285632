* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system;
}

.titulo{
   text-align: center;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-around;

}

.container-home {
  margin: 5%;
}

.migramind > img {
  max-width: 40%;
    height: 100%;
}

.migracode {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.migracode>h3 {
  padding-left: 5%;
  text-align: justify;
  line-height: 1.6;
}

.animated-shadow {
     color: #A2D6E8;
     /* font: normal 70px Genty Round, sans-serif; */
     height: 100px;
     left: 50;
     letter-spacing: 5px;
     text-align: center;
     text-transform: uppercase;
     width: 100%;
     animation: move linear 2000ms infinite;
     z-index: 2
   }

.card-body{
   height: rem;
   width: 100%;
   object-fit: cover;
   font-size: small;
}

.card-title{
   font-size:large;
}

.card-text{
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-around;
   padding-bottom: 15px;
   text-align: center;
   margin-bottom: 50;
}


.btnbtn-primary{
   padding: 0.5rem;
   width: 100px;
   margin: auto;
   border: none;
   background: #A2D6E8;
   color: #FFFFFF;
   border-radius: 560px;
   
}

/* new css style begins here  */

.about{
  font-family: 'Lato';
  font-size: 30px;
  color: #5A6E7E;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 150px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: 20px;
  padding: 20px 0;
  align-items: stretch;
  & a {
      text-decoration: none;
      flex-shrink: 1;
      display: flex;
  }
}


.card {
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5DBDB9(
      118deg,
      rgb(0 0 0) 0%,
      rgb(0, 0, 0) 50%,
      rgb(31 31 31) 100%
  );
  overflow: hidden;
  box-shadow:
      rgb(173 173 173) 20px -3px 22px -29px inset,
      rgba(0, 0, 0, 0.3) 0px 0px 1px 0px inset;
  border-radius: 20px !important;
  width: calc(33.33% - 20px);
  transition: all 0.5s;
  justify-content: space-between;
  background-size: 100% 100%;
  margin-bottom: 20px;
  
  
  @media screen and (max-width: 1024px) {
    width: calc(50% - 20px); 
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px); 
  }

  & .card-content {
      margin: 20px;
      display: flex;
      flex-direction: column;
      padding: 30px 10px;
      align-items: flex-start;
      justify-content: space-around;
      flex-grow: 1;

      & .card-description {
          color: #5A6E7E;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          padding-bottom: 20px;
          font-size: 17px;
      }
  }
}

.nav-tab {
  margin-left: 40px;
  margin-right: 30px; 
  color: #FFFFFF;
  text-decoration: none;
}

.getInfo{
  font-family: 'lato';
  padding: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: #0170bb;
  border-radius: 20px;

  & a {
    color: #0170bb;
  }

  & a:hover{
    color: #f4460f;
  }

}

.getInfo:hover{
  border-color: #f4460f;
}

.centered-image {
  text-align: center;
}

.back-to-top {
  font-weight: bolder; 
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #5DBDB9; 
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 40px; 
  font-weight: bolder; 
}